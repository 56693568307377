import React, { useState, useEffect } from 'react';
import { OptimizedImage } from './OptimizedImage';

const What = () => {
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);
  const [isHoveredLeftRow2, setIsHoveredLeftRow2] = useState(false);
  const [isHoveredRightRow2, setIsHoveredRightRow2] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "I Need This Marketing",
      image: "/slides/marketing.webp",
      width: 800,
      height: 450
    },
    {
      title: "Quantum Wellness",
      image: "/slides/quantum.webp",
      width: 800,
      height: 450
    },
    {
      title: "Bank Academy",
      image: "/slides/bank.webp",
      width: 800,
      height: 450
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(timer);
  }, []);

  // Format current time as HH:MM AM/PM
  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString('en-US', { 
      hour: 'numeric',
      minute: '2-digit',
      hour12: true 
    });
  };

  const currentTime = getCurrentTime();

  return (
    <section id="services" className="w-full bg-black text-white py-12 px-4 md:px-8">
      <div className="text-center mb-12">
        <h1 
          className="text-5xl md:text-6xl font-display font-bold bg-gradient-to-r from-[#00A3FF] via-[#7000FF] to-[#FF00E5] text-transparent bg-clip-text inline-block"
        >
          What we do
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 gap-y-12 max-w-7xl mx-auto">
        {/* Left Card */}
        <div 
          className="group relative bg-gradient-to-br from-black/40 to-black/20 backdrop-blur-xl border border-white/10 rounded-2xl p-6 hover:border-accent-blue/50 transition-all duration-300"
        >
          <div 
            onMouseEnter={() => setIsHoveredLeft(true)}
            onMouseLeave={() => setIsHoveredLeft(false)}
            className="relative bg-[#111111]/0 rounded-3xl p-6"
          >
            {/* Robot Calling Animation */}
            <div className="relative h-48 w-full flex items-center justify-center mb-6">
              {/* Main Robot */}
              <div
                className="absolute"
              >
                <svg width="80" height="80" viewBox="0 0 24 24" fill="none" className={`transition-colors duration-300 ${isHoveredLeft ? 'text-[#00A3FF]' : 'text-white'}`}>
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="currentColor"/>
                  <path d="M12 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2z" fill="currentColor"/>
                </svg>
              </div>

              {/* Orbiting Call Icons */}
              {[0, 1, 2].map((index) => (
                <div
                  key={index}
                  className="absolute"
                >
                  <div
                    className="absolute -top-2"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className={`transition-colors duration-300 ${isHoveredLeft ? 'text-[#00A3FF]' : 'text-white'}`}>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                    </svg>
                  </div>
                </div>
              ))}

              {/* Connection Lines */}
              {[0, 1, 2].map((index) => (
                <div
                  key={`line-${index}`}
                  className={`absolute w-1 h-1 rounded-full transition-colors duration-300 ${isHoveredLeft ? 'bg-[#00A3FF]/20' : 'bg-white/20'}`}
                />
              ))}
            </div>

            {/* Section Info */}
            <div className="mt-6">
              <h2 className="text-[28px] font-display font-medium text-white mb-1 group-hover:text-accent-blue transition-colors">AI Voice Agents</h2>
              <p className="text-white/60 text-[15px] max-w-[80%] whitespace-pre-line group-hover:text-white/80 transition-colors">
                Cutting-edge AI-powered communication agents delivering seamless, round-the-clock customer support with human-like precision and empathy.
              </p>
            </div>
          </div>
        </div>

        {/* Right Card */}
        <div 
          className="group relative bg-gradient-to-br from-black/40 to-black/20 backdrop-blur-xl border border-white/10 rounded-2xl p-6 hover:border-accent-blue/50 transition-all duration-300"
        >
          <div 
            onMouseEnter={() => setIsHoveredRight(true)}
            onMouseLeave={() => setIsHoveredRight(false)}
            className="relative bg-[#111111]/0 rounded-3xl p-6"
          >
            {/* Conversation Container */}
            <div className="h-[120px] overflow-hidden relative">
              <div
                className="space-y-3 text-sm transition-transform duration-1000"
                style={{
                  transform: isHoveredRight ? 'translateY(-40%)' : 'translateY(0)',
                }}
              >
                {/* User Message */}
                <div className="flex items-start gap-2 p-2.5 rounded-xl bg-[#111111] border border-[#1e1e1e]">
                  <div className="w-5 h-5 rounded-full bg-gray-700 flex-shrink-0 flex items-center justify-center">
                    <svg className="w-2.5 h-2.5 text-gray-400" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
                    </svg>
                  </div>
                  <div className="min-w-0">
                    <div className="text-gray-400 text-xs mb-0.5">You</div>
                    <div className="text-gray-300 text-sm">I'd like to order the same as yesterday.</div>
                    <div className="text-gray-500 text-xs mt-0.5">{currentTime}</div>
                  </div>
                </div>

                {/* AI Initial Response */}
                <div className="flex items-start gap-2 p-2.5 rounded-xl bg-[#111111] border border-[#1e1e1e]">
                  <div className="w-5 h-5 rounded-full bg-gray-700 flex-shrink-0 flex items-center justify-center">
                    <span className="text-[8px] font-medium text-gray-400">AI</span>
                  </div>
                  <div className="min-w-0">
                    <div>
                      <span className="text-gray-400 text-xs">AI Assistant</span>
                      <span className="text-gray-500 text-xs ml-2">{currentTime}</span>
                    </div>
                    <div className="text-gray-300 text-sm mt-0.5">Got your usual – pepperoni pizza, salad, and Pepsi. Would you like the new crispy garlic knots to go with that?</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Input Field */}
            <div className="mt-4">
              <div className={`flex items-center gap-2 p-2 rounded-xl transition-colors duration-300 ${
                isHoveredRight ? 'bg-[#1a1a1a] border border-[#00A3FF]' : 'bg-[#1a1a1a] border border-[#1e1e1e]'
              }`}>
                <span className="text-gray-400 text-sm">+</span>
                <input
                  type="text"
                  placeholder="Message AI Assistant..."
                  className="bg-transparent text-sm text-gray-300 placeholder-gray-500 flex-1 outline-none"
                  disabled
                />
                <div className="bg-gray-700/20 p-1 rounded">
                  <svg width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="text-gray-400">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Section Info */}
            <div className="mt-6">
              <h2 className="text-[28px] font-display font-medium text-white mb-1 group-hover:text-accent-blue transition-colors">AI Chatbots agent</h2>
              <p className="text-white/60 text-[15px] max-w-[80%] whitespace-pre-line group-hover:text-white/80 transition-colors">
                We design advanced AI chatbots powered by cutting-edge NLP to transform customer interactions and seamlessly optimize business processes, delivering measurable impact and efficiency.
              </p>
            </div>
          </div>
        </div>

        {/* Row 2 - Duplicate Cards */}
        {/* Duplicate of Left Card */}
        <div 
          className="group relative bg-gradient-to-br from-black/40 to-black/20 backdrop-blur-xl border border-white/10 rounded-2xl p-6 hover:border-accent-blue/50 transition-all duration-300"
        >
          <div 
            onMouseEnter={() => setIsHoveredLeftRow2(true)}
            onMouseLeave={() => setIsHoveredLeftRow2(false)}
            className="relative bg-[#111111]/0 rounded-3xl p-6"
          >
            {/* Elegant CRM Features Display */}
            <div className="relative h-48 w-full flex flex-col items-center justify-center mb-6">
              <div className="grid grid-cols-2 gap-x-12 gap-y-8 text-center max-w-[90%]">
                {[
                  {
                    title: "Smart Lead Management",
                    icon: "M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  },
                  {
                    title: "Customer Interaction",
                    icon: "M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  },
                  {
                    title: "Task Automation",
                    icon: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  },
                  {
                    title: "Team Calendar",
                    icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  }
                ].map((item, index) => (
                  <div 
                    key={index} 
                    className="group relative flex flex-col items-center transition-all duration-300"
                  >
                    {/* Icon */}
                    <div className={`mb-3 transition-all duration-300 ${isHoveredLeftRow2 ? 'text-[#00A3FF]' : 'text-[#66D2A0]'}`}>
                      <svg 
                        width="24" 
                        height="24" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="1.5" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d={item.icon} />
                      </svg>
                    </div>
                    {/* Title */}
                    <div className={`text-sm font-medium tracking-wide transition-all duration-300 ${isHoveredLeftRow2 ? 'text-[#00A3FF]' : 'text-white'}`}>
                      {item.title}
                    </div>
                    {/* Subtle Underline */}
                    <div 
                      className={`absolute -bottom-2 w-12 h-[1px] transition-all duration-300 transform scale-x-0 group-hover:scale-x-100 ${isHoveredLeftRow2 ? 'bg-[#00A3FF]' : 'bg-white'}`}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Section Info */}
            <div className="mt-6">
              <h2 className="text-[28px] font-display font-medium text-white mb-1 group-hover:text-accent-blue transition-colors">CRM AI Automations</h2>
              <p className="text-white/60 text-[15px] max-w-[80%] whitespace-pre-line group-hover:text-white/80 transition-colors">
                Transform your customer relationship management with AI-powered automation and insights.
              </p>
            </div>
          </div>
        </div>

        {/* Duplicate of Right Card */}
        <div 
          className="group relative bg-gradient-to-br from-black/40 to-black/20 backdrop-blur-xl border border-white/10 rounded-2xl p-6 hover:border-accent-blue/50 transition-all duration-300"
        >
          <div 
            onMouseEnter={() => setIsHoveredRightRow2(true)}
            onMouseLeave={() => setIsHoveredRightRow2(false)}
            className="relative bg-[#111111]/0 rounded-3xl p-6"
          >
            {/* Website Design Showcase */}
            <div className="relative w-full h-48 rounded-lg overflow-hidden">
              <div className="w-full h-full relative">
                <OptimizedImage
                  src={slides[currentSlide].image}
                  alt={slides[currentSlide].title}
                  width={slides[currentSlide].width}
                  height={slides[currentSlide].height}
                  className="w-full h-full object-cover rounded-lg"
                  priority={currentSlide === 0}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-lg">
                  <div className="absolute bottom-4 left-4 right-4 text-white">
                    <h3 className="text-lg md:text-xl font-medium">{slides[currentSlide].title}</h3>
                  </div>
                </div>
              </div>
            </div>

            {/* Section Info */}
            <div className="mt-6">
              <h2 className="text-[28px] font-display font-medium text-white mb-1 group-hover:text-accent-blue transition-colors">Website Design</h2>
              <p className="text-white/60 text-[15px] max-w-[80%] whitespace-pre-line group-hover:text-white/80 transition-colors">
                We will create sleek, high-performing websites tailored to showcase your brand and maximize your conversions effortlessly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default What;
